import Scheme from "./scheme/Scheme";
import BreadcrumbListScheme, {Breadcrumb} from "./scheme/BreadcrumbListScheme";
import NewsArticleScheme from "./scheme/NewsArticleScheme";
import AboutPageScheme from "./scheme/AboutPageScheme";
import BusinessEventScheme from "./scheme/BusinessEventScheme";
import OrganizationScheme from "./scheme/OrganizationScheme";
import {contactUsPageNumbers} from "../sections/Company/ContactUsPage/components/Numbers/Numbers";

type TPostSchema = {
  title: string;
  description: string;
  image: string;
  author: string;
  path: string;
  publishDate: string;
  post?: any
};

type FreightChildrenPagesParams = {
  title: string;
  path: string;
};

const defaultSchema = (title: string, description: string, path: string) => {
  return new Scheme(path)
    .organization()
    .set('name', `${title} | Digital Freight Alliance`)
    .set('url', Scheme.url(path))
    .set('logo', "https://content.presspage.com/uploads/2451/1920_dfalliancelogo.jpg")
    .set('image', "https://content.presspage.com/uploads/2451/1920_dfalliancelogo.jpg")
    .set('description', description)
    .getScheme()
}

const getSchema = (title: string, description: string, path: string, name: string, image?: string) => {
  const scheme = new Scheme(path)
    .webPage()
    .set('alternateName', `${title} | Digital Freight Alliance`)
    .set('description', description)
    .setBreadcrumb([{name: 'Home', url: '/'}, {name, url: path}])
  if (image) scheme.set('image', Scheme.url(image))

  return scheme.getScheme()
};

const postSchema = ({ title, description, image, author, path, publishDate, post }: TPostSchema) => {
  const breadcrumb:Breadcrumb[] = [
    {name: 'Home', url: '/'},
    {name: 'Blogs & Updates', url: '/blog'},
  ]

  if (path.replace('/blog', '').length) {
    breadcrumb.push({name: title, url: path})
  }

  const scheme = new NewsArticleScheme(path)
    .setMainEntityOfPage()
    .set('headline', title)
    .set('description', description)
    .setBreadcrumb(breadcrumb)

  if (image) scheme.set('image', Scheme.url(image));
  if (author) scheme.setAuthor(author);
  if (publishDate) scheme.set('datePublished', new Date(publishDate).toJSON());
  if (post) scheme.set('dateModified', new Date(post.updated_at).toJSON())

  return scheme.getScheme();
};

export const webSchemas = {
  '/': defaultSchema,
  '/services/trade-finance': () => new Scheme('/services/trade-finance')
    .webPage()
    .set('alternateName', 'Trade Finance')
    .set('description', 'Creating access to affordable finance for businesses of all sizes.')
    .set('image', Scheme.url('/images/main/logisticsFinance/intro/1.png'))
    .set('keywords', ["Trade Finance", "Commercial Finance"])
    .set('significantLink', 'https://login.dpworld.com/auth/realms/DPWorld/protocol/openid-connect/auth?state=eyJ1dG1fc291cmNlIjoiIiwidXRtX21lZGl1bSI6IiIsInV0bV9jYW1wYWlnbiI6IiIsInBhZ2UtbmFtZSI6ImZpbmFuY2UiLCJidXR0b24tbmFtZSI6InZpc2l0LWNhcmdvZXMtZmluYW5jZSJ9&client_id=CF&response_type=code&redirect_uri=https://finance.dpworld.com/home/def')
    .setBreadcrumb([{name: 'Home', url: '/'}, {name: 'Trade Finance', url: '/services/trade-finance'},])
    .getScheme(),

  '/benefits/digital-tools/cargoes-flow': () => new Scheme('/benefits/digital-tools/cargoes-flow')
    .webPage()
    .set('alternateName', 'CARGOES Flow')
    .set('description', 'The container tracking tool that tracks ocean shipments across 100s of shipping lines, all in one easy to use interface. See the status of your shipments in real time.')
    .set('image', Scheme.url('/images/pages/Services/CargoesFlow/header_ship.png'))
    .set('keywords', ["Container Tracking", "Shipping Tracking"])
    .set('significantLink', Scheme.url('/benefits/digital-tools/cargoes-flow#what-is'))
    .setBreadcrumb([{name: 'Home', url: '/'}, {name: 'Benefits', url: '/benefits'}, {name: 'Digital Tools', url: '/benefits/digital-tools'}, {name: 'CARGOES Flow', url: '/benefits/digital-tools/cargoes-flow'},])
    .getScheme(),

  '/benefits/digital-tools/tracking-system': () => new Scheme('/benefits/digital-tools/tracking-system')
    .webPage()
    .set('alternateName', 'Tracking System')
    .set('description', 'The ultimate cargo tracking tool that works on any desktop or mobile device. Let your customers know where their cargo is and view it on the map without having to call you! Get access to tracking data from more than 160 shipping carriers.')
    .set('image', Scheme.url('/images/pages/Services/TrackingSystem/safari_white.svg'))
    .set('keywords', ["Cargo Tracking System", "Shipping Tracking System"])
    .setBreadcrumb([{name: 'Home', url: '/'}, {name: 'Benefits', url: '/benefits'}, {name: 'Digital Tools', url: '/benefits/digital-tools'}, {name: 'Tracking System', url: '/benefits/digital-tools/tracking-system'},])
    .getScheme(),

  '/benefits/digital-tools/searates-erp': () => new Scheme('/benefits/digital-tools/searates-erp')
    .webPage()
    .set('alternateName', 'SeaRates ERP')
    .set('description', 'Know about SeaRates ERP.')
    .set('image', Scheme.url('/images/pages/Services/SeaRatesERP/section_1_main.svg'))
    .set('keywords', ["SeaRates ERP", "ERP System"])
    .setBreadcrumb([{name: 'Home', url: '/'}, {name: 'Benefits', url: '/benefits'}, {name: 'Digital Tools', url: '/benefits/digital-tools'}, {name: 'SeaRates ERP', url: '/benefits/digital-tools/searates-erp'},])
    .getScheme(),

  '/auth/sign-in/greetings': (title: string, description: string, path: string) => getSchema(title, description, path, 'Greetings'),

  '/resources/terms-and-conditions': (title: string, description: string, path: string) => getSchema(title, description, path, 'Terms and Conditions'),
  '/resources/privacy-policy': (title: string, description: string, path: string) => getSchema(title, description, path, 'Privacy Policy'),
  '/resources/membership-regulations': (title: string, description: string, path: string) => getSchema(title, description, path, 'Membership Regulations', '/images/main/members-regulations/members-regulations.svg',),
  // '/resources/greetings': (title: string, description: string, path: string) => getSchema(title, description, path, 'Greetings'),
  '/resources/code-of-ethics': (title: string, description: string, path: string) => getSchema(title, description, path, 'Code of Ethics', '/images/main/codeOfEthic/code_of_ethics_bg.png'),
  '/resources/blacklist': (title: string, description: string, path: string) => getSchema(title, description, path, 'Blacklist'),
  '/resources/bill-of-lading': (title: string, description: string, path: string) => getSchema(title, description, path,'Bill of Lading', '/images/pages/Resources/BillOfLading/mobile/hero-212.png'),
  '/resources/demurrage-and-detention': (title: string, description: string, path: string) => getSchema(title, description, path, 'Demurrage and Detention', '/images/pages/Resources/DemurrageAndDetention/mobile/hero-360.png',),

  '/membership': (title: string, description: string, path: string) => getSchema(title, description, path, 'Membership'),

  '/feedback/experience-survey': (title: string, description: string, path: string) => getSchema(title, description, path, 'Experience Survey'),
  '/feedback/expectations-survey': (title: string, description: string, path: string) => getSchema(title, description, path,'Expectations Survey'),

  // '/company/help-center': (title: string, description: string, path: string) => getSchema(title, description, path,'Help Center'),
  '/faqs': (title: string, description: string, path: string) => getSchema(title, description, path,'FAQs'),

  '/benefits/support': (title: string, description: string, path: string) => getSchema(title, description, path, 'Support', '/images/main/support/image.png'),
  '/benefits/shipping-leads': (title: string, description: string, path: string) => getSchema(title, description, path, 'Shipping Leads', '/images/main/your-rates/bg-md.jpg'),
  '/benefits/payment-protection': (title: string, description: string, path: string) => getSchema(title, description, path, 'Payment Protection', '/images/main/your-rates/bg-md.jpg'),
  '/benefits/networking': (title: string, description: string, path: string) => getSchema(title, description, path, 'Networking', '/images/main/freight-forwarders/image.png'),
  '/benefits/digital-tools': (title: string, description: string, path: string) => getSchema(title, description, path, 'Digital Tools', '/images/pages/Benefits/DigitalTools/header_background.png'),
  '/benefits/digital-solution': (title: string, description: string, path: string) => getSchema(title, description, path, 'Digital Solution', '/images/main/digital-solution/image.png'),
  '/benefits/brand-reliability': (title: string, description: string, path: string) => getSchema(title, description, path, 'Brand Reliability', '/images/main/service-reliability/image.png'),


  '/events/members-meeting': (title: string, description: string, path: string) => new BusinessEventScheme(path)
    .setName(title).setUrl(path).setDescription(description)
    .setStartDate('19 December 2024')
    .setOffers('2024-10-19')
    .setLocation('online', 'https://crmmarketing.powerappsportals.com/dfallianceinvitepage/')
    .getScheme(),
  '/events/global-freight-summit': (title: string, description: string, path: string) => new BusinessEventScheme(path)
    .setName(title).setUrl(path).setDescription(description)
    .setStartDate('18 November 2024').setEndDate('19 November 2024')
    .setOffers('2024-09-18')
    .setLocation('Dubai Exhibition Centre, Expo City')
    .getScheme(),
  '/events/future-of-freight': (title: string, description: string, path: string) => new BusinessEventScheme(path)
    .setName(title).setUrl(path).setDescription(description)
    .setStartDate('19 July 2022, 15:00 UTC+7 (GMT+7)')
    .setOffers('2022-04-19')
    .setLocation('online', 'https://www.youtube.com/watch?v=fRpK-6Ck9AY')
    .getScheme(),
  '/events/1st-dfa-annual-conference': (title: string, description: string, path: string) => new BusinessEventScheme(path)
    .setName(title).setUrl(path).setDescription(description)
    .setStartDate('10 March 2022').setEndDate('11 March 2022')
    .setOffers('2022-04-19')
    .setLocation('Dubai Exhibition Centre, Expo City')
    .getScheme(),

  '/company/vision-strategy': (title: string, description: string, path: string) => new AboutPageScheme(path)
    .setMainEntityOfPage()
    .setName(title)
    .setUrl(path)
    .setDescription(description)
    .getScheme(),
  '/company/contact-us': (title: string, description: string, path: string) => new OrganizationScheme(path)
    .setUrl('/')
    .setContactPoint(contactUsPageNumbers.map(({phone}) => phone))
    .getScheme(),

  '/freight-dictionary': (title: string, _:string, path: string) => new BreadcrumbListScheme(path)
    .setItemListElement([
      {name: 'Home', url: '/'},
      {name: title, url: '/freight-dictionary'},
    ]).getScheme(),
  freightDictionaryChildren: ({path, title}: FreightChildrenPagesParams) => new BreadcrumbListScheme(path)

    .setItemListElement([
      {name: 'Home', url: '/'},
      {name: 'Freight Dictionary | All Freight Terms with Explanations - DF Alliance', url: '/freight-dictionary'},
      {name: title, url: path},
    ]).getScheme(),

  '/freight-forwarder': (title: string) => new BreadcrumbListScheme()
    .setItemListElement([
      {name: 'Home', url: '/'},
      {name: title, url: '/freight-forwarder'},
    ]).getScheme(),
  freightForwarderChildren: ({path, title}: FreightChildrenPagesParams) => new BreadcrumbListScheme()
    .setItemListElement([
      {name: 'Home', url: '/'},
      {name: 'How to Become a Freight Forwarder | DF Alliance', url: '/freight-forwarder'},
      {name: title, url: path},
    ], true).getScheme(),

  post: postSchema,
};
